import React from 'react';
import { css } from '@emotion/core';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { LandingPageCatQuery } from '../../../graphql-types';

import IndividualPageLayout from '../../components/individualPageLayout';
import SEO from '../../components/seo';
import Header from '../../components/header/lp/Header';
import Footer from '../../components/lp/Footer';
import ImageCarousel from '../../components/lp/search-landing-page/ImageCarousel';
import LocationsNav from '../../components/lp/search-landing-page/LocationsNav';
import NewYorkLocations from '../../components/lp/search-landing-page/NewYorkLocations';
import ReviewCarousel from '../../components/lp/search-landing-page/ReviewCarousel';
import SocialMediaPics from '../../components/lp/search-landing-page/SocialMediaPics';

import { BREAKPOINTS } from '../../ui/variables';
import Heading2 from '../../ui/Heading2';
import Heading3 from '../../ui/Heading3';
import ParagraphExLarge from '../../ui/ParagraphExLarge';
import {
  BookCTA,
  FontWrapper,
  Hero,
  HeroContent,
  HeroText,
  HeroTitle,
  Underline,
  HeroParagraph,
  HeroCTA,
  HeroImage,
  Perks,
  PerksContainer,
  PerksWrapper,
  PerksImg,
  PerksText,
  Text,
  ServicePhoto,
  Services,
  ServicesContainer,
  ServicesList,
  ServiceLink,
  Arrow,
  Testimonials,
  Uppercase,
  Wrapper,
  Banner,
  BannerContainer,
  BannerTitle,
} from '../../components/lp/search-landing-page/components';

export const pageQuery = graphql`
  query LandingPageCat {
    allContentfulReviewCard(limit: 3) {
      nodes {
        name
        reviewBody {
          reviewBody
        }
        reviewerAvatar {
          fixed(width: 60, height: 60) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
    }
    heroImage: file(
      relativePath: { eq: "pages/lp/search-landing-page/cat/hero-image.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 492) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    happyCat: file(relativePath: { eq: "graphics/cat-happy-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    highFive: file(relativePath: { eq: "graphics/high-five.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dogHurt: file(relativePath: { eq: "graphics/dog-with-hurt-ear.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    chillCat: file(relativePath: { eq: "graphics/cat-happy.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    baoBao: file(
      relativePath: {
        eq: "pages/lp/search-landing-page/cat/dr-jamie-bao-bao-cropped.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 432, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;

interface Props {
  data: LandingPageCatQuery;
}

const LandingPageCat = ({ data }: Props) => {
  const reviews = data.allContentfulReviewCard.nodes.map(review => ({
    name: review.name,
    review: review.reviewBody.reviewBody,
    starCount: 5,
    avatar: review.reviewerAvatar.fixed,
  }));

  return (
    <IndividualPageLayout>
      <FontWrapper>
        <Header
          sticky={false}
          v2colors={true}
          cta={{
            text: 'Book Now',
            href: `${process.env.GATSBY_NEW_ONBOARDING_URL}signup`,
          }}
        />

        <SEO
          title={'Exceptional Vet Care for Your Cat'}
          description={
            'Small Door Veterinary is a membership-based veterinary practice that puts you and your cat first. Get priority appointments, stress-free spaces & 24/7 telehealth.'
          }
        />

        <Hero>
          <HeroContent>
            <HeroText>
              <HeroTitle>
                The <Underline>best pet care </Underline>
                <br />
                for all nine lives.
              </HeroTitle>
              <HeroParagraph>
                Welcome to Small Door, the membership-based veterinary practice
                that puts you and your cat first.{' '}
                <em>We’re an entirely different animal.</em>
              </HeroParagraph>
              <HeroCTA>
                <BookCTA />
                <span
                  css={css`
                    font-size: 18px;
                    @media ${BREAKPOINTS.TABLET} {
                      font-size: 16px;
                    }
                    @media ${BREAKPOINTS.MOBILE} {
                      margin-top: 32px;
                      font-size: 16px;
                    }
                  `}
                >
                  Or call us at <a href='tel:+12129339044'>(212) 933-9044</a>
                </span>
              </HeroCTA>
            </HeroText>
            <HeroImage>
              <Img
                fluid={data.heroImage.childImageSharp.fluid}
                alt="Photograph of Dr. Jamie Richardson with a cat and it's owner."
              />
            </HeroImage>
          </HeroContent>
        </Hero>

        <LocationsNav />

        <ImageCarousel />

        <Perks>
          <Uppercase>The small door difference</Uppercase>
          <Heading2
            css={css`
              margin-top: 32px;
              margin-bottom: 100px;
              @media ${BREAKPOINTS.MOBILE} {
                margin-top: 24px;
              }
            `}
          >
            Everything you could ask for&nbsp;in&nbsp;a&nbsp;vet
          </Heading2>
          <PerksContainer>
            <PerksWrapper>
              <PerksImg>
                <Img
                  fluid={data.happyCat.childImageSharp.fluid}
                  alt='Illustration of a happy cat.'
                />
              </PerksImg>
              <PerksText>
                <Heading3>We get cats</Heading3>
                <Text>
                  Our doctors know everything there is to know about cats and
                  they’re trained in low-stress feline handling techniques. Plus
                  with longer appointment windows, we give your cat or kitten
                  ample time to warm up to their doctor for a successful visit.{' '}
                </Text>
              </PerksText>
            </PerksWrapper>
            <PerksWrapper>
              <PerksImg>
                <Img
                  fluid={data.highFive.childImageSharp.fluid}
                  alt='Illustration of a person and a dog doing a high five.'
                />
              </PerksImg>
              <PerksText>
                <Heading3>Annual exam included</Heading3>
                <Text>
                  Your membership includes one annual exam to keep your pet
                  healthy, and provide personalized recommendations for their
                  wellbeing. We will let you know the costs of additional
                  services such as vaccines, lab tests, surgeries, and
                  medication before treatment.
                </Text>
              </PerksText>
            </PerksWrapper>
            <PerksWrapper>
              <PerksImg>
                <Img
                  fluid={data.dogHurt.childImageSharp.fluid}
                  alt='Illustration of a dog with a bandage.'
                />
              </PerksImg>
              <PerksText>
                <Heading3>Appointments when you need them</Heading3>
                <Text>
                  We believe it shouldn't take days to see your doctor –
                  membership means your cat can get the care they need, when
                  they need it. Plus you have access to all five Small Door
                  locations in NYC.
                </Text>
              </PerksText>
            </PerksWrapper>
            <PerksWrapper>
              <PerksImg>
                <Img
                  fluid={data.chillCat.childImageSharp.fluid}
                  alt='Illustration of a cat sleeping.'
                />
              </PerksImg>
              <PerksText>
                <Heading3>24/7 telehealth access</Heading3>
                <Text>
                  Accidents can happen at any time of day. Whenever you need it,
                  chat with our medical team for immediate advice and peace of
                  mind.
                </Text>
              </PerksText>
            </PerksWrapper>
          </PerksContainer>
        </Perks>

        <Services>
          <Uppercase>Services</Uppercase>
          <Wrapper>
            <Heading2
              css={css`
                margin-bottom: 0;
                @media ${BREAKPOINTS.MOBILE} {
                  margin-bottom: 48px;
                }
              `}
            >
              Full service pet care, all in one place
            </Heading2>
            <BookCTA />
          </Wrapper>
          <ServicesContainer>
            <ServicePhoto>
              <Img
                fluid={data.baoBao.childImageSharp.fluid}
                alt='Dr. Jamie examine Bao Bao the cat'
              />
            </ServicePhoto>
            <div
              css={css`
                align-self: center;
                @media ${BREAKPOINTS.MOBILE} {
                  align-self: auto;
                }
              `}
            >
              <ServicesList>
                <li>
                  <p>24/7 telemedicine</p>
                </li>
                <li>
                  <p>Surgical procedures</p>
                </li>
                <li>
                  <p>Vaccinations</p>
                </li>
                <li>
                  <p>Travel certificates</p>
                </li>
                <li>
                  <p>Pharmacy</p>
                </li>
                <li>
                  <p>Dentistry & teeth cleaning</p>
                </li>
                <li>
                  <p>Diagnostic testing & radiography</p>
                </li>
                <li>
                  <p>Behavioral consultations</p>
                </li>
              </ServicesList>
              <ServiceLink to='/services'>
                See all our services <Arrow />
              </ServiceLink>
            </div>
          </ServicesContainer>
        </Services>

        <Testimonials>
          <ReviewCarousel
            title='Join the thousands of New Yorkers who trust Small Door'
            reviews={reviews}
          />
          <SocialMediaPics />
        </Testimonials>

        <NewYorkLocations />

        <Banner>
          <BannerContainer>
            <BannerTitle>Give your cat the care they deserve.</BannerTitle>
            <ParagraphExLarge
              css={css`
                margin-bottom: 40px;
              `}
            >
              Experience the difference of membership-based pet care. Membership
              includes an annual exam, 24/7 telehealth, and same or next day
              appointments. All for just $169/pet/year.
            </ParagraphExLarge>
            <BookCTA />
          </BannerContainer>
        </Banner>

        <Footer />
      </FontWrapper>
    </IndividualPageLayout>
  );
};

export default LandingPageCat;
